import React from "react";
import { GET_TRAINING_EVENT } from "../../../graphql/query/TrainingQuery";
import Error from "../../../pages/_error";
import { DateService } from "../../../service/DateService";
import Authenticated from "../../Authenticated";
import BaseLink from "../../BaseLink";
import Col from "../../theme/3.0/Grid/Col";
import Container from "../../theme/3.0/Grid/Container";
import Row from "../../theme/3.0/Grid/Row";
import Page from "../../theme/3.0/Page/Page";
import Tab from "../../theme/3.0/Tab/Tab";
import TabLink from "../../theme/3.0/Tab/TabLink";
import Tabs from "../../theme/3.0/Tab/Tabs";
import theme from "../../theme/3.0/theme";
import CapacityBuildingRegisterButton from "./CapacityBuildingRegisterButton";
import { authenticateUser } from "../../../utils/AuthenticationUtility";
import CapacityBuildingRegisterLink from "./CapacityBuildingRegisterLink";
import { getCookiesFromCtx } from "../../../utils/CommonUtils";
import ContentfulService from "../../../service/ContentfulService";
import EventRegisterLink from "../news-and-events/EventView/EventRegisterLink";
import { Stream } from "@cloudflare/stream-react";

const CapacityBuildingContainer = (Component) => {
    const CapacityBuildingPage = ({ article, currentRouteKey }) => {
        if (typeof article === "undefined" || article === null) {
            // @ts-ignore POSSIBLE ERROR FOUND
            // TS TODO: Error is a Page Component
            return <Error statusCode={404} />;
        }
        const inFuture = DateService.inFuture(DateService.parse(article.date));
        const bannerImage =
            article.bannerImage?.fields?.file?.url ||
            article.posterImage ||
            article.image;
        // console.log({ article, currentRouteKey, inFuture });
        return (
            <Authenticated passNullUser={true}>
                {(user, authorizationService) => {
                    let displayRegistrationButton = false;

                    if (article.enableRegistration === true) {
                        if (user !== null) {
                            displayRegistrationButton = true;
                        } else if (
                            user === null &&
                            article.enableGuestRegistration === true
                        ) {
                            displayRegistrationButton = true;
                        }
                    }
                    return (
                        <Page
                            pageTitle={`${article.name} | Capacity Building`}
                            user={user}
                            authorizationService={authorizationService}
                            currentRouteKey={currentRouteKey}
                            breadcrumbs={[
                                {
                                    label: "Calendar",
                                    routeKey: "event-calendar"
                                },
                                {
                                    label: article.name,
                                    routeKey: "capacity-building-view",
                                    params: { id: article.id }
                                }
                            ]}
                        >
                            <header>
                                <Container>
                                    <Row>
                                        <Col
                                            lg={inFuture ? 8 : 8}
                                            md={inFuture ? 8 : 8}
                                            sm={inFuture ? 12 : 12}
                                            xs={inFuture ? 12 : 12}
                                        >
                                            <div className={"header-banner"}>
                                                {user &&
                                                article.cloudflareVideoId ? (
                                                    <div>
                                                        <div className="mb-4">
                                                            <Stream
                                                                className="stream"
                                                                controls
                                                                src={
                                                                    article.cloudflareVideoId
                                                                }
                                                                responsive
                                                                // height="50"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    bannerImage && (
                                                        <img
                                                            src={`${bannerImage}?fit=scale&w=1000&fm=jpg&fl=progressive&q=90`}
                                                            className={
                                                                "capacity-building-banner"
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </Col>
                                        <Col
                                            lg={inFuture ? 4 : 4}
                                            md={inFuture ? 4 : 4}
                                            sm={inFuture ? 12 : 12}
                                            xs={inFuture ? 12 : 12}
                                        >
                                            <div className={"header-info"}>
                                                {article.startDate && (
                                                    <time
                                                        dateTime={
                                                            article.startDate
                                                        }
                                                    >
                                                        {DateService.format(
                                                            article.startDate,
                                                            DateService.FORMAT_EEEE_MMM_EE_YYYY
                                                        )}
                                                    </time>
                                                )}
                                                <h1>{article.name}</h1>
                                                <div className={"venue-name"}>
                                                    {article.venueName}
                                                </div>
                                                {/* {displayRegistrationButton &&
                                                    inFuture && (
                                                        <CapacityBuildingRegisterButton
                                                            user={user}
                                                            // TS FOUND ERROR: no prop authorizationService
                                                            // @ts-ignore
                                                            authorizationService={
                                                                authorizationService
                                                            }
                                                            article={article}
                                                        />
                                                    )}
                                                {!displayRegistrationButton &&
                                                    article.registrationLink !==
                                                        null &&
                                                    article.registrationLink !==
                                                        "" &&
                                                    inFuture && (
                                                        <CapacityBuildingRegisterLink
                                                            link={
                                                                article.registrationLink
                                                            }
                                                        />
                                                    )} */}
                                                {article.register_link &&
                                                    inFuture && (
                                                        <EventRegisterLink
                                                            user={user}
                                                            article={article}
                                                        />
                                                    )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </header>
                            <section className={"menu"}>
                                <Container>
                                    <Tabs margin={"0 0 0 0"}>
                                        <Tab>
                                            <BaseLink
                                                routeKey={
                                                    "capacity-building-view"
                                                }
                                                params={{ id: article.id }}
                                            >
                                                {({ url }) => (
                                                    <TabLink
                                                        href={url}
                                                        active={
                                                            "capacity-building-view" ===
                                                            currentRouteKey
                                                        }
                                                    >
                                                        Overview
                                                    </TabLink>
                                                )}
                                            </BaseLink>
                                        </Tab>
                                        <Tab>
                                            <BaseLink
                                                routeKey={
                                                    "capacity-building-view-documents"
                                                }
                                                params={{ id: article.id }}
                                            >
                                                {({ url }) => (
                                                    <TabLink
                                                        href={url}
                                                        active={
                                                            "capacity-building-view-documents" ===
                                                            currentRouteKey
                                                        }
                                                    >
                                                        Documents
                                                    </TabLink>
                                                )}
                                            </BaseLink>
                                        </Tab>
                                        {article.relatedArticles &&
                                            article.relatedArticles.length >
                                                0 && (
                                                <Tab>
                                                    <BaseLink
                                                        routeKey={
                                                            "capacity-building-view-related-articles"
                                                        }
                                                        params={{
                                                            id: article.id
                                                        }}
                                                    >
                                                        {({ url }) => (
                                                            <TabLink
                                                                href={url}
                                                                active={
                                                                    "capacity-building-view-related-articles" ===
                                                                    currentRouteKey
                                                                }
                                                            >
                                                                Related Articles
                                                            </TabLink>
                                                        )}
                                                    </BaseLink>
                                                </Tab>
                                            )}
                                    </Tabs>
                                </Container>
                            </section>
                            <Component
                                article={article}
                                user={user}
                                authorizationService={authorizationService}
                            />
                            <style jsx global>{`
                                section {
                                    margin-bottom: 1rem;
                                    position: relative;
                                }

                                section:last-of-type {
                                    margin-bottom: 0;
                                }

                                .header-info {
                                    margin-top: 2rem;
                                    margin-bottom: 2rem;
                                }

                                .header-info h1 {
                                    margin: 0 0 1rem 0;
                                }

                                .menu {
                                    border-top: 1px solid
                                        ${theme.colour.gray200};
                                    border-bottom: 1px solid
                                        ${theme.colour.gray200};
                                }

                                .details,
                                .description {
                                }

                                .description :global(p) {
                                    margin-top: 0;
                                }

                                .capacity-building-banner {
                                    display: block;
                                    width: 100%;
                                }
                                #content {
                                    margin-top: -90px;
                                }
                                #content > div:first-child {
                                    margin-top: 90px !important;
                                }
                                .page-breadcrumbs {
                                    background-color: ${theme.colour
                                        .white} !important;
                                }
                                ol,
                                #content > ul {
                                    padding-inline-start: 40px;
                                    margin-top: 0;
                                    margin-bottom: 1rem;
                                }
                                ul {
                                    list-style-type: disc;
                                }
                                .stream {
                                    max-height: 450px;
                                    height: 80vh;
                                    overflow: hidden;
                                    padding-top: 0 !important;
                                }
                            `}</style>
                        </Page>
                    );
                }}
            </Authenticated>
        );
    };

    // Set the correct displayName in development (from: https://github.com/zeit/next.js/blob/canary/examples/with-apollo/lib/apollo.js)
    if (process.env.NODE_ENV !== "production") {
        const displayName =
            Component.displayName || Component.name || "Component";
        CapacityBuildingPage.displayName = `CapacityBuildingContainer(${displayName})`;
    }

    CapacityBuildingPage.getInitialProps = async (ctx) => {
        // TS FOUND ERROR: extected 3 args
        // TS CODE CHANGE removed 4th arg `true`
        await authenticateUser(ctx.apolloClient, ctx, undefined);
        const cookiesJSON = getCookiesFromCtx(ctx);
        const contentfulService = ContentfulService(cookiesJSON);
        const id = ctx.query.id;
        let page = "overview";
        let title = "View Event Article";
        let description = null;
        let article = null;
        let eventsAndTotal = {};
        let newsArticlesAndTotal = {};

        article = await contentfulService.getSingleArticle(id);
        if (article) {
            title =
                (article.seoTitle ? article.seoTitle : article.title) +
                " - " +
                article.type +
                " - Convergence Event";
            description = article.seoDescription
                ? article.seoDescription
                : null;
            return {
                article,
                currentRouteKey: ctx.query.routeKey
            };
        } else {
            if (ctx && ctx.res) {
                ctx.res.statusCode = 404;
            }
            // ctx.res.end("News article not found");
            return {
                article: null,
                currentRouteKey: ctx.query.routeKey
            };
        }
    };

    // let data = null;
    // try {
    //     const queryData = await ctx.apolloClient.query({
    //         query: GET_TRAINING_EVENT,
    //         variables: {
    //             id
    //         }
    //     });
    //     data = queryData.data ? queryData.data : null;
    // } catch (e) {
    //     //console.error(e);
    // }

    // const event = data.event ? data.event : null;

    // if (!event) {
    //     if (ctx && ctx.res) {
    //         ctx.res.statusCode = 404;
    //     }

    //     return {
    //         article: null,
    //         currentRouteKey: ctx.query.routeKey
    //     };
    // }

    // };

    return CapacityBuildingPage;
};

export default CapacityBuildingContainer;
