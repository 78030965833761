import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { USER_STATUS_ACTIVE } from "../../../../constants";

const EventRegisterLink = ({ user, article }) => {
    if (article.access === "Members") {
        if (user && user.status === USER_STATUS_ACTIVE) {
            return (
                <React.Fragment>
                    <a
                        href={article.register_link}
                        className={"btn btn-success btn-block mt-3"}
                        target={"_blank"}
                    >
                        Register
                    </a>
                    {article.registrationCode && (
                        <React.Fragment>
                            <br />
                            <label className={"font-weight-bold"}>
                                Event Registration Code:
                            </label>
                            <input
                                className={"form-control form-block"}
                                type={"input"}
                                disabled={true}
                                defaultValue={article.registrationCode}
                                readOnly={true}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        } else {
            let currentPath = `/calendar/${article.id}/view`;

            return (
                <React.Fragment>
                    <a
                        href={"/login?redirect=" + currentPath}
                        className={"btn btn-light btn-block mt-3"}
                        title={"Members only"}
                        id={"register-button"}
                    >
                        Login to Register
                    </a>
                    <UncontrolledTooltip
                        placement="bottom"
                        target={"register-button"}
                        delay={0}
                    >
                        Only Convergence members can register for this event.
                    </UncontrolledTooltip>
                </React.Fragment>
            );
        }
    }

    return (
        <React.Fragment>
            <a
                href={article.register_link}
                className={"btn btn-success btn-block mt-3"}
                target={"_blank"}
            >
                Register
            </a>
            {article.registrationCode && (
                <React.Fragment>
                    <br />
                    <label className={"font-weight-bold"}>
                        Event Registration Code:
                    </label>
                    <input
                        className={"form-control form-block"}
                        type={"input"}
                        disabled={true}
                        defaultValue={article.registrationCode}
                        readOnly={true}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default EventRegisterLink;
