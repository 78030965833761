/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import rehypeSlug from "rehype-slug";
import remarkHeadingId from "remark-heading-id";

const lookupMime = (url) => {
    const ext = url.split(".").pop();

    if (ext === "mp4") {
        return "video";
    }

    return "unknown";
};

const MarkdownRenderer = ({ content }) => (
    <ReactMarkdown
        className="article-content"
        components={{
            br: () => {
                return <br />;
            },
            table: ({ children }) => {
                return (
                    <table className={"table table-bordered"}>{children}</table>
                );
            },
            a: ({ ...props }) => {
                const videoUrl = props.href;
                // const thumbnailUrl = props.children[0].props.src;
                if (lookupMime(videoUrl) === "video") {
                    return (
                        <video controls>
                            <source src={videoUrl} type="video/mp4" />
                            <div>
                                Your browser doesn't support HTML5 video. Here
                                is a <a href={videoUrl}>link to the video</a>{" "}
                                instead.
                            </div>
                            <style jsx>{`
                                video {
                                    display: block;
                                    max-width: 100%;
                                }
                            `}</style>
                        </video>
                    );
                }
                if (videoUrl.indexOf("https://www.youtube.com/watch?v=") > -1) {
                    const embedId = videoUrl.replace(
                        "https://www.youtube.com/watch?v=",
                        ""
                    );
                    return (
                        <>
                            <style jsx>
                                {`
                                    .video-responsive {
                                        overflow: hidden;
                                        padding-bottom: 56.25%;
                                        position: relative;
                                        height: 0;
                                    }

                                    .video-responsive iframe {
                                        left: 0;
                                        top: 0;
                                        height: 100%;
                                        width: 100%;
                                        position: absolute;
                                    }
                                `}
                            </style>
                            <div className="video-responsive">
                                <iframe
                                    width="853"
                                    height="480"
                                    src={`https://www.youtube.com/embed/${embedId}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Embedded youtube"
                                />
                            </div>
                        </>
                    );
                }
                // @ts-ignore POSSIBLE ERROR FOUND.
                // Could be solved with return <link {...props} />;
                // TS FOLLOW UP
                return <a {...props} />;
            }
            // h2: (...params) => {
            //     console.log(params);
            //     return <h2>{params[0].children}</h2>;
            // }
        }}
        // @ts-ignore downgrade related
        // TS tested
        remarkPlugins={[remarkHeadingId, [remarkGfm, { singleTilde: false }]]}
        // @ts-ignore
        // TS tested
        rehypePlugins={[rehypeRaw, rehypeSanitize, rehypeSlug]}
        // It seems like it's just a TS issue: https://github.com/hashicorp/next-mdx-remote/issues/86
    >
        {content}
    </ReactMarkdown>
);

MarkdownRenderer.propTypes = {
    content: PropTypes.string.isRequired
};

export default MarkdownRenderer;
