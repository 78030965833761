import dynamic from "next/dynamic";
import React from "react";
import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";
import MarkdownRenderer from "../../../../components/general/MarkdownRenderer";
import Col from "../../../../components/theme/3.0/Grid/Col";
import Container from "../../../../components/theme/3.0/Grid/Container";
import Row from "../../../../components/theme/3.0/Grid/Row";
import theme from "../../../../components/theme/3.0/theme";
import { DateService } from "../../../../service/DateService";
import { withApollo } from "../../../../utils/WithApollo";
import CapacityBuildingContainer from "../../../../components/page/capacity-building/CapacityBuildingContainer";
import ICalendarLink from "react-icalendar-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Map = dynamic(() => import("../../../../components/map/GoogleMap"), {
    ssr: false
});

export const createDateTimeRange = (startDateString, endDateString) => {
    if (typeof startDateString === "undefined" || startDateString === null) {
        return "N/A";
    }

    const startDate = DateService.parse(startDateString);

    if (typeof endDateString === "undefined" || endDateString === null) {
        return DateService.format(startDate, "PPPP 'at' hh:mm a OOOO");
    }

    const endDate = DateService.parse(endDateString);

    const startDay = `${startDate.getUTCDay()}${startDate.getUTCMonth()}${startDate.getUTCFullYear()}`;
    const endDay = `${endDate.getUTCDay()}${endDate.getUTCMonth()}${endDate.getUTCFullYear()}`;

    if (startDay === endDay) {
        const startTime = DateService.format(startDate, "p");
        const endTime = DateService.format(endDate, "p (OOOO)");

        return (
            <React.Fragment>
                {DateService.format(startDate, "PPPP")}
                <br />
                {startTime} - {endTime}
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {DateService.format(startDate, "eeee, PP 'at' hh:mm aaa")} -{" "}
                {DateService.format(endDate, "eeee, PP 'at' hh:mm aaa (OOOO)")}
            </React.Fragment>
        );
    }
};

const inFuture = (article) =>
    DateService.inFuture(DateService.parse(article.date));

let ViewCapacityBuildingPageComponent = ({ user, article }) => (
    <React.Fragment>
        <section>
            <Container>
                <Row>
                    <Col lg={8} md={8} sm={8} xs={12}>
                        {article.description && (
                            <div className={"description"}>
                                {article.postEventDescription &&
                                !DateService.inFuture(
                                    new Date(article.endDate || article.date)
                                ) ? (
                                    <>
                                        <MarkdownRenderer
                                            content={
                                                article.postEventDescription
                                            }
                                        />
                                    </>
                                ) : (
                                    <MarkdownRenderer
                                        content={article.description}
                                    />
                                )}
                                {/* {
                                    <MarkdownRenderer
                                        content={article.description}
                                    />
                                } */}
                            </div>
                        )}
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={12}>
                        <div className={"details"}>
                            {article.date && (
                                <React.Fragment>
                                    <h5>Date And Time</h5>
                                    <p>
                                        {createDateTimeRange(
                                            article.date,
                                            article.endDate
                                        )}
                                    </p>
                                    {/* <br /> */}
                                    {inFuture(article) && (
                                        // @ts-ignore
                                        <ICalendarLink
                                            event={{
                                                title: article.name,
                                                startTime: article.date,
                                                endTime: article.endDate,
                                                description: article.register_link
                                                    ? `Register here: ${article.register_link}`
                                                    : ""
                                            }}
                                            filename={article.name
                                                .replace(/\s/g, "-")
                                                .toLowerCase()}
                                        >
                                            <FontAwesomeIcon
                                                icon={
                                                    faCalendarPlus as IconProp
                                                }
                                            />{" "}
                                            Add to Calendar
                                        </ICalendarLink>
                                    )}
                                </React.Fragment>
                            )}
                            {(article.location || article.venueName) && (
                                <React.Fragment>
                                    <h5>Location</h5>
                                    <p>
                                        {article.venueName && (
                                            <React.Fragment>
                                                {article.venueName}
                                                <br />
                                            </React.Fragment>
                                        )}
                                        {article.location && (
                                            <React.Fragment>
                                                {article.location}
                                            </React.Fragment>
                                        )}
                                    </p>
                                </React.Fragment>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        <br />
        {typeof article.venueLocation !== "undefined" &&
            article.venueLocation !== null &&
            article.venueLocation.lon &&
            article.venueLocation.lat && (
                <section>
                    {/* @ts-ignore POSSIBLE ERROR FOUND */}
                    <Map
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        className="mb-3"
                        defaultZoom={15}
                        defaultCenter={{
                            lat: article.venueLocation.lat,
                            lng: article.venueLocation.lon
                        }}
                        defaultOptions={{
                            disableDefaultUI: true,
                            zoomControl: true,
                            fullscreenControl: true,
                            streetViewControl: true,
                            styles: [
                                {
                                    featureType: "administrative",
                                    elementType: "labels.text.fill",
                                    stylers: [
                                        {
                                            color: theme.colour.gray800
                                        }
                                    ]
                                },
                                {
                                    featureType: "landscape",
                                    elementType: "all",
                                    stylers: [
                                        {
                                            color: theme.colour.gray200
                                        }
                                    ]
                                },
                                {
                                    featureType: "poi",
                                    elementType: "all",
                                    stylers: [
                                        {
                                            visibility: "off"
                                        }
                                    ]
                                },
                                {
                                    featureType: "road",
                                    elementType: "all",
                                    stylers: [
                                        {
                                            saturation: -100
                                        },
                                        {
                                            lightness: 45
                                        }
                                    ]
                                },
                                {
                                    featureType: "road.highway",
                                    elementType: "all",
                                    stylers: [
                                        {
                                            visibility: "simplified"
                                        }
                                    ]
                                },
                                {
                                    featureType: "road.arterial",
                                    elementType: "labels.icon",
                                    stylers: [
                                        {
                                            visibility: "off"
                                        }
                                    ]
                                },
                                {
                                    featureType: "transit",
                                    elementType: "all",
                                    stylers: [
                                        {
                                            visibility: "off"
                                        }
                                    ]
                                },
                                {
                                    featureType: "water",
                                    elementType: "all",
                                    stylers: [
                                        {
                                            color: theme.colour.primary
                                        },
                                        {
                                            visibility: "on"
                                        }
                                    ]
                                }
                            ]
                        }}
                    >
                        {() => (
                            <MarkerWithLabel
                                position={{
                                    lat: article.venueLocation.lat,
                                    lng: article.venueLocation.lon
                                }}
                                // @ts-ignore POSSIBLE ERROR FOUND
                                labelAnchor={new window.google.maps.Point(0, 0)}
                            >
                                <div />
                            </MarkerWithLabel>
                        )}
                    </Map>
                </section>
            )}
    </React.Fragment>
);

const ViewCapacityBuildingPage = CapacityBuildingContainer(
    ViewCapacityBuildingPageComponent
);

export default withApollo(ViewCapacityBuildingPage);
